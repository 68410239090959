import React from "react";
import { FaFacebook, FaWhatsapp, FaLinkedin, FaTelegram } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <div className="bg-[#000300] w-full mx-auto py-5 px-6 lg:px-16 grid lg:grid-cols-3 gap-4 text-gray-400">
        <div>
          <h1 className="w-full text-3xl font-bold text-slate-50">
            OriginLand
          </h1>
          <p className="py-4 text-sm font-sans">
            Origin land general trading PLC is registered in Ethiopia to engage
            in Agricultural products export mainly Green Arabica Coffee, Roasted
            Coffee, Minerals, oil seeds, pulses, spices industrial and chemical
            products import to Ethiopia
          </p>
          <div className="flex justify-between mx-auto md:w-[80%] my-6">
          <a
              href="https://www.facebook.com/originlandethiopia?mibextid=ZbWKwL"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook
                size={26}
                className="text-gray-400 hover:text-gray-100"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/originlandgeneraltradingplc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedin
                size={26}
                className="text-gray-400 hover:text-gray-100"
              />
            </a>
            <a
              href="https://t.me/ejg1234"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTelegram
                size={26}
                className="text-gray-400 hover:text-gray-100"
              />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=251912502288"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp
                size={26}
                className="text-gray-400 hover:text-gray-100"
              />
            </a>
          </div>
        </div>
        <div className="lg:col-span-2 flex justify-between lg:px-44 mt-6">
          <div>
            <h6 className="font-semibold text-gray-200">Company</h6>
            <ul className="flex flex-col">
              <a href="/about" className="py-2 text-sm hover:text-gray-200">
                About OriginLand
              </a>
              <a href="/projects" className="py-2 text-sm hover:text-gray-200">
                Our Works
              </a>
              <a href="/services" className="py-2 text-sm hover:text-gray-200">
                Services
              </a>
            </ul>
          </div>
          <div>
            <h6 className="font-semibold text-gray-200">Contact</h6>
            <ul>
              <li className="py-2 text-sm">
                Call Us: +251-922-119955 / +251912502288{" "}
              </li>
              <li className="py-2 text-sm">
                Email Us: info@originlandgeneraltradingplc.com
              </li>
              <li className="py-2 text-sm">
                Address: Bambis, Addis Ababa, Ethiopia
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-[#000300] w-full flex justify-center items-center mx-auto pb-2">
        <p className="text-gray-50 mx-auto">
          OriginLand 2023 | All rights reserved &copy;
        </p>
      </div>
    </div>
  );
};

export default Footer;
