import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Import from "./pages/Import";
import Export from "./pages/Export";
import Meat from "./pages/Meat";
import Exports from "./pages/Exports";





const App = () => {
  return (
    <div className="">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<Hero />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/contact" exact element={<Contact />} />
          <Route path="/services/import" exact element={<Import />} />
          <Route path="/services/export" exact element={<Export />} />
          <Route path="/services/exports" exact element={<Exports />} />
          <Route path="/services/export/meat" exact element={<Meat />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App