import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { motion } from "framer-motion";
import "react-slideshow-image/dist/styles.css";
import { Fade } from "react-slideshow-image";

const slideimgs = [
  {
    img: "/meat.png",
  },
  {
    img: "/meat2.jpg",
  },
  {
    img: "/meat3.jpg",
  },
];

const divStyle = {};

const page = () => {
  return (
    <div className="mt-24 pb-10">
      <motion.div
        initial={{ opacity: 0, y: 80 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0 }}
      >
        <div className="flex items-center justify-center bg-cover md:h-[300px]">
          <img src="/meat1.jpg" alt="" className="h-[300px] w-full" />
          <div className="font-bold rounded flex flex-col p-5 max-w-lg absolute mx-auto m-3 opacity-90 duration-500 ease-in-out">
            <h1 className="text-3xl md:text-4xl text-slate-50 pb-5 ">
              {" "}
              {"Meat Export"}
            </h1>
          </div>
        </div>
        <div className="md:flex md:mt-10 justify-center gap-14 items-center pb-16">
          <div className="slide-container">
            <Fade duration={1000} arrows={false}>
              {slideimgs.map((img, index) => (
                <div key={index} className=" ">
                  <div
                    className="flex items-center justify-center"
                    style={{
                      ...divStyle,
                      backgroundimg: `url(${img.img})`,
                    }}
                  ></div>
                </div>
              ))}
            </Fade>
          </div>
          <div className="md:w-[300px] mt-10">
            <Fade duration={3000} arrows={false}>
              {slideimgs.map((img, index) => (
                <div key={index} className=" ">
                  <div className="flex items-center justify-center">
                    {/* Use 'img' tag to display imgs directly */}
                    <img
                      width={400}
                      height={300}
                      src={img.img}
                      alt={`Slide ${index}`}
                      className="w-[400px] h-[250px] object-cover rounded-lg"
                    />
                  </div>
                </div>
              ))}
            </Fade>
          </div>
          <div className="px-10">
            <h2 className="mt-5 text-blue-950 text-2xl font-bold">
              Meat Export
            </h2>
            <p className="max-w-xl py-3 pb-8">
              These meats are rich in essential nutrients, including protein,
              iron, zinc, and B vitamins. Their popularity extends beyond
              domestic markets, with a growing demand for export. The succulent
              cuts and culinary versatility make them prized in diverse cuisines
              worldwide. Additionally, edible organs like liver, kidneys, and
              heart are cherished for their unique taste and nutritional
              benefits.
            </p>
            <button className="flex justify-center items-center border bg-blue-950 hover:bg-blue-800 rounded-lg px-8 py-3 text-slate-50 font-bold">
              <a href="/services/import">Learn more</a>
              <span className="ml-2 flex justify-center items-center">
                <FaArrowRight size={12} className="text-white" />
              </span>
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default page;
