import React from "react";
import { FaArrowRight } from "react-icons/fa";

const Export = () => {
  return (
    <div className="bg-gray-50 mt-20">
      <div className="w-full text-black bg-gray-50 md:pb-">
        <div className="mx-6 md:mx:18 lg:mx-32 py-8">
          <h1 className="uppercase font font-extrabold text-xl lg:text-3xl font-sans mb-3 flex justify-center mx-auto">
            We export
          </h1>
          <p className="text-slate-700 max-w-2xl pb-5 flex justify-center mx-auto text-center text-sm lg:text-md">
            We offer a selection of premium coffee, spices, and pulses. Feel
            free to reach out to us for products that guarantee both quality and
            reliability, establishing trust in your sourcing experience.
          </p>
        </div>
      </div>
      <div className="md:flex justify-center gap-14 items-center pb-16 mx-3">
        <img
          src={"/meat.png"}
          width={400}
          height={200}
          alt="img"
          className="mb-2 md:mb-0 md:flex rounded-md h-[250px]"
        />
        <div>
          <h2 className="text-blue-950 text-xl md:text-2xl font-extrabold">
            Meat
          </h2>
          <p className="max-w-xl py-3 pb-8 text-gray-700 ">
            These meats are rich in essential nutrients, including protein,
            iron, zinc, and B vitamins. Their popularity extends beyond domestic
            markets, with a growing demand for export. The succulent cuts and
            culinary versatility make them prized in diverse cuisines worldwide.
            Additionally, edible organs like liver, kidneys, and heart are
            cherished for their unique taste and nutritional benefits.
          </p>
          <button className="flex justify-center items-center border bg-blue-950 hover:bg-blue-800 rounded-lg px-8 py-3 text-slate-50 font-bold">
            <a href="/services/export/meat">Learn more</a>
            <span className="ml-2 flex justify-center items-center">
              <FaArrowRight size={12} className="text-white" />
            </span>
          </button>
        </div>
      </div>
      <div className="md:flex justify-center gap-14 items-center pb-16 mx-3">
        <img
          src={"/coffee1.jpg"}
          width={400}
          height={200}
          alt="img"
          className="mb-2 md:mb-0 md:flex rounded-md"
        />
        <div>
          <h2 className="text-blue-950 text-xl md:text-2xl font-extrabold">
            Ethiopian Coffee
          </h2>
          <p className="max-w-xl py-3 pb-8 text-gray-700 ">
            Ethiopian coffee is renowned globally for its exceptional quality
            and unique flavors. Originating from regions like Sidamo,
            Yirgacheffe, and Harrar, Ethiopian coffee is often characterized by
            its bright acidity, floral notes, and fruity undertones. The
            traditional coffee ceremony is a cultural hallmark, emphasizing the
            social significance of coffee in Ethiopian communities.
          </p>
          <button className="flex justify-center items-center border bg-blue-950 hover:bg-blue-800 rounded-lg px-8 py-3 text-slate-50 font-bold">
            <a href="/services/export">Learn more</a>
            <span className="ml-2 flex justify-center items-center">
              <FaArrowRight size={12} className="text-white" />
            </span>
          </button>
        </div>
      </div>
      <div className="md:flex justify-center gap-14 items-center pb-16 mx-3">
        <img
          src={"/pulse.jpg"}
          width={400}
          height={200}
          alt="img"
          className="mb-2 md:mb-0 md:flex rounded-md"
        />
        <div>
          <h2 className="text-blue-950 text-xl md:text-2xl font-extrabold">
          Chickpeas, Lentils, Pulses
          </h2>
          <p className="max-w-xl py-3 pb-8 text-gray-700">
            Ethiopian pulses, such as lentils and chickpeas, are staple
            ingredients in the country's cuisine. Packed with protein, fiber,
            and essential nutrients, pulses form the basis of many traditional
            dishes like wat and injera. These versatile and nutritious legumes
            contribute to the heartiness and nutritional balance of Ethiopian
            meals
          </p>
          <button className="flex justify-center items-center border bg-blue-950 hover:bg-blue-800 rounded-lg px-8 py-3 text-slate-50 font-bold">
            <a href="/services/export">Learn more</a>
            <span className="ml-2 flex justify-center items-center">
              <FaArrowRight size={12} className="text-white" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Export;
