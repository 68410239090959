import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { HiBars3, HiPhone } from "react-icons/hi2";
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { LiaTimesSolid } from "react-icons/lia";
import { FaWhatsapp } from "react-icons/fa";

import { nav_links } from "../constants";

const Navbar = () => {
  const pathname = useLocation();
  const [nav, setNav] = useState(false);
  const navHandle = () => {
    setNav(!nav);
  };

  return (
    <>
      <header className="top-0 left-0 w-full fixed z-[9999] py-3 px-4 bg-white border-b opacity-90">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <div className="">
              <a href={"/"}>
                <img
                  src={"/origin_logo.png"}
                  width={200}
                  height={200}
                  alt="img"
                />
              </a>
            </div>

            {/* Navigation Menu  */}

            <div className="hidden lg:block uppercase">
              <ul className="flex space-x-10 font-sans text-sm">
                <li>
                  <a
                    href="/"
                    className={
                      pathname === "/"
                        ? "text-gray-950 border-b-2 border-slate-500 pb-1 font-extrabold"
                        : "text-slate-800 font-semibold hover:text-gray-950 hover:border-b hover:border-slate-500 pb-1 ease-in-out duration-105"
                    }
                  >
                    Home
                  </a>
                </li>
                <div className="relative ml-4 group text-gray-200 font-semibold">
                  <button className="text-slate-800 focus:outline-none pb-2 uppercase">
                    Services / Products
                  </button>
                  <div className="absolute hidden bg-white py-3 px-3 rounded-md shadow-lg group-hover:flex flex-col ease-in-out duration-300 w-56">
                    <a
                      href="/services/import"
                      className="py-2 px-2 rounded text-gray-800 border-b hover:text-sky-100 hover:bg-sky-700"
                    >
                      Import
                    </a>
                    <a
                      href="/services/exports"
                      className="py-2 px-2 rounded text-gray-800 hover:text-sky-100 hover:bg-sky-700 cursor-pointer"
                    >
                      Export
                    </a>
                  </div>
                </div>

                <li>
                  <a
                    href="/about"
                    className={
                      pathname === "/about"
                        ? "text-gray-950 border-b-2 border-slate-500 pb-1 font-extrabold"
                        : "text-slate-800 font-semibold hover:text-gray-950 hover:border-b hover:border-slate-500 pb-1 ease-in-out duration-105"
                    }
                  >
                    About us
                  </a>
                </li>
                <li>
                  <a
                    href="/contact"
                    className={
                      pathname === "/contact"
                        ? "text-gray-950 border-b-2 border-slate-500 pb-1 font-extrabold"
                        : "text-slate-800 font-semibold hover:text-gray-950 hover:border-b hover:border-slate-500 pb-1 ease-in-out duration-105"
                    }
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
            <div className="font-sans text-sm">
              <div className="hidden lg:flex flex-col">
                <div className="flex gap-3 justify-start items-center pb-1">
                  <HiPhone size={19} className="text-blue-900" />
                  <p>+251-922-119955 / +251-912-502288 </p>
                </div>
                <div className="flex gap-3 justify-center items-center pb-1">
                  <IoIosMail size={24} className="text-blue-900" />
                  <p>info@originlandgeneraltradingplc.com</p>
                </div>
                <div className="flex gap-3 justify-start items-center">
                  <FaLocationDot size={22} className="text-blue-900" />
                  <p>Bambis, Addis Ababa, Ethiopia</p>
                </div>
              </div>
              <button className="block lg:hidden" onClick={navHandle}>
                {!nav ? <HiBars3 size={35} /> : <LiaTimesSolid size={35} />}
              </button>
            </div>
          </div>
        </div>
      </header>
      <a
        href="https://api.whatsapp.com/send?phone=251912502288"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="bg-green-600 h-16 w-16 fixed right-10 top-[600px] z-[9999] flex justify-center items-center rounded-full">
          <FaWhatsapp size={40} className="text-white" />
        </div>
      </a>

      {/* Mobile Navigation */}

      <div
        className={
          nav
            ? "z-[9999] fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-gray-950 ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <div className="flex items-center justify-center py-3">
          <a href={"/"}>
            <img src={"/origin_logo.png"} width={200} height={200} alt="img" />
          </a>
        </div>

        <ul className="p-4">
          {nav_links.map((a) => (
            <li key={a.key} className="p-4 border-b border-gray-900">
              <a href={a.href} className="uppercase text-white">
                {a.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Navbar;
